import { Auth, UserInfo as FirebaseUser } from 'firebase/auth'
import { UserGroups } from '~/config'

export const useUserStore = defineStore('user', () => {
  const user = ref(
    useLocalStorage('wolfpack-user-local-storage', {}) as unknown as
      | FirebaseUser
      | Record<string, never>
  )
  const overwrittenUserGroups = ref(
    useLocalStorage('access-override', []) as unknown as UserGroups[]
  )

  const auth = ref<Auth>()
  const isUserLoggedIn = ref(false)
  const isUserAdmin = ref(false)
  const userGroups = ref<UserGroups[]>([])

  return {
    auth,
    isUserLoggedIn,
    isUserAdmin,
    user,
    userGroups,
    overwrittenUserGroups
  }
})
