<template>
  <div class="min-h-screen bg-softlight-500">
    <AppHeader />
    <div class="mx-auto max-w-[1768px] px-5 pb-5">
      <NuxtLayout>
        <NuxtPage v-if="userGroups.length" />
      </NuxtLayout>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PageName } from './config'
import { CommunicationManager } from './services/communicationManager'

const route = useRoute()
const { userGroups } = storeToRefs(useUserStore())

const previousUserGroups = ref(userGroups.value)

onMounted(() => {
  // activate page must be called during setup of this component, else, services cannot import from composables
  CommunicationManager.activatePage(route.name as PageName)
})

watch(
  () => userGroups.value,
  async (value) => {
    if (value.length === 0) {
      return
    }

    if (areArraysEqual(value, previousUserGroups.value)) {
      return
    }

    previousUserGroups.value = value

    // nextTick is needed to wait for the page to be rendered after changing the userGroups
    // after the page is rendered, we can activate the page to obtain data from the services
    await nextTick()

    CommunicationManager.activatePage(route.name as PageName)
  }
)

function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  return arr1.every((val, index) => val === arr2[index])
}

watch(
  () => route.name,
  (value) => {
    CommunicationManager.activatePage(value as PageName)
  }
)
</script>
