<template>
  <div
    class="elevation-3 rounded-2xl bg-white"
    :class="`card-${props.padding}`"
    @click="emit('click')"
  >
    <slot />
    <slot name="footer" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  padding: {
    type: String,
    default: 'md',
    validator(value: string) {
      return ['none', 'sm', 'md', 'lg', 'xl'].includes(value)
    }
  }
})

const emit = defineEmits(['click'])
</script>

<style lang="scss" scoped>
.card-sm {
  @apply p-2;
}

.card-md {
  @apply p-4;
}

.card-lg {
  @apply p-6;
}

.card-xl {
  @apply p-8;
}
</style>
