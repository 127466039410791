import {
  CollectionReference,
  DocumentData,
  FirestoreDataConverter,
  onSnapshot,
  Query,
  QuerySnapshot,
  Unsubscribe
} from 'firebase/firestore'
import { PageName } from '~/config'
import { FirestoreType } from '../composables/useFirebase'
import { Service } from './service'

export abstract class LiveService<
  DataType extends FirestoreType,
  ReturnType extends DataType | DataType[] | Record<string, DataType>
> extends Service<DataType, ReturnType> {
  protected cachedData: Map<number, ReturnType> = new Map()
  private unsubscribes: Unsubscribe[] = []
  protected lastQueries: Map<PageName, Query[]>
  protected collection: CollectionReference
  protected collectionGroup?: Query<DataType, DocumentData>
  protected collectionConstructor: <DataType extends FirestoreType>(
    collectionName: string,
    ...pathSegments: string[]
  ) => CollectionReference<DataType, DocumentData>
  private converter: FirestoreDataConverter<DataType, DocumentData>

  constructor(collectionName: string, collectionGroupName?: string) {
    super()
    this.lastQueries = new Map()

    const { collection: collectionConstructor, collectionGroup } = useFirebase()
    this.collection = collectionConstructor(collectionName)

    if (collectionGroupName) {
      this.collectionGroup = collectionGroup(collectionName)
    }
    this.collectionConstructor = collectionConstructor
    const { firestoreGenericConverter } = useFirebase()
    this.converter = firestoreGenericConverter<DataType>()
  }

  public override activatePage(pageName: PageName): void {
    this.activePage = pageName

    if (this.lastQueries.has(pageName)) {
      this.unsubscribeListeners()

      const queries = this.lastQueries.get(pageName)!
      this.listenGroup(queries)
    }

    for (const subscriber of this.subscribers) {
      subscriber.activatePage(pageName)
    }
  }

  protected listenGroup(queries: Query[]) {
    this.lastQueries.set(this.activePage, queries)
    this.unsubscribeListeners()
    this.unsubscribes = queries.map((query, index) => {
      return onSnapshot(query.withConverter(this.converter), (snapshot) =>
        /* v8 ignore next - not woth testing */
        this.updateInternalState.bind(this)(snapshot, index)
      )
    })
  }

  protected listen(query: Query): void {
    this.listenGroup([query])
  }

  protected updateInternalState(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number = 0
  ): void {
    const newState = this.parseToReturnType(snapshot, index)

    const amountOfQueries = this.lastQueries.get(this.activePage)?.length
    if (this.cachedData.size !== amountOfQueries) {
      return
    }

    this.updateSubscriberStates(newState)
  }

  protected unsubscribeListeners(): void {
    for (const unsubscribe of this.unsubscribes) {
      unsubscribe()
    }

    this.cachedData.clear()
    this.unsubscribes = []
  }

  protected abstract parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): ReturnType
}

export class ArrayService<DataType extends FirestoreType> extends LiveService<
  DataType,
  DataType[]
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): DataType[] {
    const newData = []

    for (const doc of snapshot.docs) {
      newData.push(doc.data())
    }

    this.cachedData.set(index, newData)

    return Array.from(this.cachedData.values()).flat()
  }
}

export class MapService<DataType extends FirestoreType> extends LiveService<
  DataType,
  Record<string, DataType>
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): Record<string, DataType> {
    const newData: Record<string, DataType> = {}
    // Gather the data
    for (const doc of snapshot.docs) {
      newData[doc.id] = doc.data()
    }

    this.cachedData.set(index, newData)
    const combinedData: Record<string, DataType> = {}
    for (const data of this.cachedData.values()) {
      Object.assign(combinedData, data)
    }
    return combinedData
  }
}

export class ModelService<DataType extends FirestoreType> extends LiveService<
  DataType,
  DataType
> {
  protected parseToReturnType(
    snapshot: QuerySnapshot<DataType, DocumentData>,
    index: number
  ): DataType {
    const data = snapshot.docs[0].data()
    this.cachedData.set(index, data)
    return data
  }
}
