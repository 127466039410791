import { PageName } from '~/config'
import { Controller } from '~/features/controller'
import { ControllerState } from '~/features/controllerState'

export abstract class Service<
  DataType,
  ReturnType extends DataType | DataType[] | Record<string, DataType>
> {
  protected subscribers: ControllerState<ReturnType>[]
  protected activePage!: PageName

  constructor() {
    this.subscribers = []
  }

  public subscribe(
    controller: Controller,
    sideEffect?: () => void
  ): ControllerState<ReturnType> {
    const controllerState = new ControllerState<ReturnType>(
      controller,
      sideEffect
    )
    this.subscribers.push(controllerState)
    return controllerState
  }

  public activatePage(pageName: PageName): void {
    this.activePage = pageName

    for (const subscriber of this.subscribers) {
      subscriber.activatePage(pageName)
    }
  }

  protected updateSubscriberStates(data: ReturnType): void {
    for (const subscriber of this.subscribers) {
      subscriber.setState(data)
    }
  }

  protected getEmptyState(): DataType {
    return {} as DataType
  }
}
