import { getStorage } from '@firebase/storage'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import {
  addDoc as addDocument,
  collectionGroup as collectionGroupRef,
  collection as collectionRef,
  CollectionReference,
  doc as docRef,
  DocumentReference,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc as setDocument,
  Timestamp,
  type DocumentData,
  type FirestoreDataConverter,
  type WithFieldValue
} from 'firebase/firestore'
import { UserGroups } from '~/config'

export default function useFirebase() {
  const config = useRuntimeConfig()
  const {
    user,
    auth,
    isUserLoggedIn,
    isUserAdmin,
    userGroups,
    overwrittenUserGroups
  } = storeToRefs(useUserStore())
  const { toast } = useHelpers()
  const { t } = useI18n()
  const router = useRouter()
  const { apiPost } = useApi()
  const { reportError } = useSentry()

  const firebaseConfig = {
    apiKey: config.public.FB_API_KEY,
    authDomain: `${config.public.FB_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${config.public.FB_PROJECT_ID}.firebaseio.com`,
    projectId: config.public.FB_PROJECT_ID,
    storageBucket: `${config.public.FB_PROJECT_ID}.appspot.com`,
    messagingSenderId: config.public.FB_MESSAGING_SENDER_ID,
    appId: config.public.FB_APP_ID,
    measurementId: config.public.FB_MEASUREMENT_ID
  }

  const firebaseApp = initializeApp(firebaseConfig)

  const storageLogistic = getStorage(
    firebaseApp,
    config.public.GC_STORAGE_LOGISTIC
  )
  const storageApro = getStorage(firebaseApp, config.public.GC_STORAGE_APRO)

  const storagePrescriptionAttachments = getStorage(
    firebaseApp,
    config.public.GC_MEDAPP_PRESCRIPTION_STORAGE
  )

  // Authentication
  const provider = new GoogleAuthProvider()
  const fbAuth = getAuth()
  auth.value = fbAuth

  onAuthStateChanged(fbAuth, async (user: unknown) => {
    // Log out user if no user is present
    // TODO: Move this functionality into a store. This is called a lot of times
    //    and not shared amoung different usages.
    if (!user) {
      isUserLoggedIn.value = false
      isUserAdmin.value = false
      console.log('Resetting user groups')
      userGroups.value = []
      overwrittenUserGroups.value = []
      signOutUser()
      return
    }
    const tokenResult = await fbAuth.currentUser?.getIdTokenResult()
    const userGroupClaims = tokenResult?.claims['userGroups']
    isUserLoggedIn.value = true

    // Overwrite user groups if they are set (admin only)
    if (overwrittenUserGroups.value.length > 0 && isUserAdmin.value === true) {
      userGroups.value = overwrittenUserGroups.value
      return
    }

    // Log in the user normally
    if (userGroupClaims) {
      userGroups.value = userGroupClaims as UserGroups[]
      console.log(userGroups.value)
      isUserAdmin.value = userGroups.value.includes(UserGroups.Admin)
      console.log('User groups:', userGroups.value)
      console.log('Is user admin:', isUserAdmin.value)
    }
  })

  function firebaseLogin() {
    if (!fbAuth) {
      return
    }
    console.log('firebaseLogin called')
    signInWithPopup(fbAuth, provider).then(async (result) => {
      if (!result.user?.email) return

      console.log('signInWithPopup called')
      try {
        await apiPost('user/authenticate', {
          headers: {
            'Content-Type': 'application/json'
          }
        })

        user.value = result.user
        router.push('/')
        return
      } catch (error) {
        toast.error(t('firebase.noAccess'))
        signOutUser()
        return
      }
    })
  }

  function signOutUser() {
    if (!fbAuth) {
      return
    }

    signOut(fbAuth).then(() => {
      user.value = {}
      router.push('/inloggen')
    })
  }

  // used for the firestore refs
  const db = getFirestore(firebaseApp)

  // Generic converter
  const firestoreGenericConverter = <T extends FirestoreType>() =>
    ({
      toFirestore(data) {
        const { ...doc } = data
        return doc
      },
      fromFirestore(snapshot): T {
        const data = {
          ...snapshot.data(),
          id: snapshot.id,
          ref: snapshot.ref
        } as T
        return data
      }
    }) as FirestoreDataConverter<T>

  const doc = <T extends FirestoreType>(
    collectionName: string,
    ...pathSegments: string[]
  ) =>
    docRef(db, collectionName, ...pathSegments).withConverter(
      firestoreGenericConverter<T>()
    )

  const collection = <T extends FirestoreType>(
    collectionName: string,
    ...pathSegments: string[]
  ) =>
    collectionRef(db, collectionName, ...pathSegments).withConverter(
      firestoreGenericConverter<T>()
    )

  const collectionGroup = <T extends FirestoreType>(collectionName: string) =>
    collectionGroupRef(db, collectionName).withConverter(
      firestoreGenericConverter<T>()
    )

  const addDoc = <T extends FirestoreType>(
    reference: CollectionReference<T>,
    data: Omit<WithFieldValue<T>, keyof FirestoreType>
  ) => {
    return addDocument(reference, {
      ...data,
      Created: serverTimestamp(),
      Updated: serverTimestamp()
    } as WithFieldValue<T>)
  }

  const setDoc = <T extends FirestoreType>(
    reference: DocumentReference<T, DocumentData>,
    data: Omit<WithFieldValue<T>, keyof FirestoreType>
  ) => {
    return setDocument(reference, data as WithFieldValue<T>)
  }

  const getDocument = async <T extends FirestoreType>(path: string) => {
    const documentSnaphot = await getDoc(
      doc(path).withConverter(firestoreGenericConverter<T>())
    )
    if (!documentSnaphot.exists()) {
      reportError('Document does not exist', { path })
      return
    }
    return documentSnaphot.data()
  }

  return {
    addDoc,
    addDocument,
    setDoc,
    collection,
    collectionGroup,
    collectionRef,
    db,
    doc,
    docRef,
    firebaseLogin,
    firestoreGenericConverter,
    getDocument,
    signOutUser,
    storageApro,
    storageLogistic,
    storagePrescriptionAttachments,
    user
  }
}

export type FirestoreType = {
  id: string
  ref: DocumentReference<DocumentData>
  Created: Timestamp
  Updated: Timestamp
}
