import { Service } from './service'

export abstract class PullService<
  DataType,
  ReturnType extends DataType | DataType[] | Record<string, DataType>
> extends Service<DataType, ReturnType> {
  /**
   * Decorator that wraps the annotated function to call the updateState method of the service.
   */
  public static CallableFunction<ReturnType>() {
    return function (
      target: any,
      propertyKey: string,
      descriptor: PropertyDescriptor
    ) {
      const originalfunction = descriptor.value
      descriptor.value = async function (...args: any[]) {
        const service = this as PullService<any, ReturnType>
        const data = await originalfunction.apply(this, args, true)
        service.updateSubscriberStates(data)
      }
    }
  }
}
