import { query } from 'firebase/firestore'
import { MaisUser } from '~/models/maisUser'
import { ArrayService } from './serviceLive'

export class MaisUserService extends ArrayService<MaisUser> {
  constructor() {
    super('MaisUsers')
  }

  public listenForAll(): void {
    this.listen(query(this.collection))
  }
}
