import { documentId, query, where } from 'firebase/firestore'
import { ArrayService } from './serviceLive'
import { InsuranceCompany } from '~/models'

export class InsuranceCompanyService extends ArrayService<InsuranceCompany> {
  constructor() {
    super('InsuranceCompanies')
  }

  public listenForId(insuranceCompanyId: string): void {
    this.listen(
      query(this.collection, where(documentId(), '==', insuranceCompanyId))
    )
  }
}
