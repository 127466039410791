import { limit, query, where } from 'firebase/firestore'
import { MedicineArticle } from '~/models'
import { ArrayService } from './serviceLive'

export class MedicineArticleService extends ArrayService<MedicineArticle> {
  constructor() {
    super('MedicineArticles')
  }

  public listenForArticleNumber(articleNumber: number): void {
    const start =
      articleNumber * Math.pow(10, 8 - articleNumber.toString().length)

    const end =
      (articleNumber + 1) * Math.pow(10, 8 - articleNumber.toString().length) -
      1

    const listenQuery = query(
      this.collection,
      where('ArticleNumber', '>=', start),
      where('ArticleNumber', '<=', end),
      limit(50)
    )
    this.listen(listenQuery)
  }

  public listenForArticleName(articleName: string): void {
    const words = articleName.split(' ').map((word) => word.toLowerCase())
    const listenQuery = query(
      this.collection,
      where(
        'AllSearchTerms',
        'array-contains',
        words.length > 0 ? words[0] : ''
      ),
      limit(50)
    )
    this.listen(listenQuery)
  }
}
